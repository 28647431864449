import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import styled from 'react-emotion';
import { withLocale } from './LanguageProvider';
import Toggle from './Toggle';
import MobileMenu from './MobileMenu';

const SignatureText = styled('span')`
  font-family: 'Damion', cursive;
  color: white;
  font-weight: 300;
  text-transform: none;
  font-size: 1.5em;
  white-space: nowrap;
  margin-left: 20px;
  z-index: 100;
`;

const ToggleWrapper = styled('div')`
  margin: 10px;
`;

const NavMenu = styled(({ className, links }) => (
  <ul className={className}>
    {links.map(({ onClick, label }) => (
      <li key={label}>
        <a onClick={onClick}>{label}</a>
      </li>
    ))}
  </ul>
))`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  align-content: center;
  justify-content: center;
  margin: 0;
  padding: 10px;

  > li {
    display: inline-block;
    margin: 0 7px;
    white-space: nowrap;

    > a {
      cursor: pointer;
      text-shadow: 0 0 10px black;
      transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
      background: transparent;
      outline-color: rgb(85, 203, 255);
      font-weight: 400;

      padding: 5px;
      text-decoration: none;
      color: white;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
      border: none;
      border-bottom: 2px solid transparent;
      font-size: 1em;
      text-transform: uppercase;

      &:hover {
        color: rgb(85, 203, 255);
        border-bottom-color: rgb(85, 203, 255);
      }
    }
  }
`;

const OPACITY_OFFSET = 50;
const OPACITY_RANGE = 150;
const OPACITY_MAX_VALUE = 0.9;
class BaseNav extends Component {
  nav = React.createRef();
  hasOpacityBeenSet = false;

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const posY =
      window.pageYOffset || (document.documentElement || {}).scrollTop;
    this.setOpacity(this.calculateOpacity(posY));
  };

  calculateOpacity = posY => {
    if (posY < OPACITY_OFFSET) return 0;
    if (posY > OPACITY_RANGE) return OPACITY_MAX_VALUE;
    return (
      ((posY - OPACITY_OFFSET) * OPACITY_MAX_VALUE) /
      (OPACITY_RANGE - OPACITY_OFFSET)
    );
  };

  setOpacity = opacity => {
    if (!this.hasOpacityBeenSet) this.hasOpacityBeenSet = true;
    this.nav.current.style.backgroundColor = `rgba(0,0,0,${opacity})`;
  };

  render() {
    const { className, links, setLocale, language } = this.props;
    console.log(this.nav && this.nav.current);

    return (
      <nav className={className} ref={this.nav}>
        <SignatureText>Álvaro Reneses</SignatureText>

        <MediaQuery minWidth={650}>
          <NavMenu links={links} />
          <ToggleWrapper>
            <Toggle
              value={language}
              onToggle={locale => setLocale(locale)}
              options={[
                {
                  label: 'EN',
                  value: 'en'
                },
                {
                  label: 'ES',
                  value: 'es'
                }
              ]}
            />
          </ToggleWrapper>
        </MediaQuery>
        <MediaQuery maxWidth={649}>
          <MobileMenu
            links={links}
            language={language}
            onLanguageToggle={locale => setLocale(locale)}
          />
        </MediaQuery>
      </nav>
    );
  }
}

export default styled(withLocale(BaseNav))`
  background: rgba(0, 0, 0, 0); // TODO this opacity will be animated
  position: fixed;
  text-transform: uppercase;
  z-index: 100;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

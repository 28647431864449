import React, { Component, createContext } from 'react';

import enStrings from '../locales/en.json';
import esString from '../locales/es.json';

const stringsMap = {
  en: enStrings,
  es: esString
};

const DEFAULT_LANGUAGE = 'en';

const getStrings = language =>
  stringsMap[language] || stringsMap[DEFAULT_LANGUAGE];

const LanguageContext = createContext({
  language: null,
  strings: null
});

export class LanguageProvider extends Component {
  state = {
    language: DEFAULT_LANGUAGE,
    strings: getStrings(DEFAULT_LANGUAGE)
  };

  componentDidMount() {
    this.setLocale(this.urlLanguage || this.browserLanguage);
  }

  get browserLanguage() {
    const language =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;

    return language.toLowerCase().split(/[_-]+/)[0];
  }

  get urlLanguage() {
    const { location } = this.props;
    return location.pathname.replace('/', '');
  }

  setLocale = language => {
    const { history } = this.props;
    this.setState(
      {
        language,
        strings: getStrings(language)
      },
      () => history.replace(language)
    );
  };

  render() {
    const { children } = this.props;
    return (
      <LanguageContext.Provider
        value={{ ...this.state, setLocale: this.setLocale }}
      >
        {children}
      </LanguageContext.Provider>
    );
  }
}

export const withLocale = WrappedComponent => props => (
  <LanguageContext.Consumer>
    {({ strings, setLocale, language }) => (
      <WrappedComponent
        strings={strings}
        language={language}
        setLocale={setLocale}
        {...props}
      />
    )}
  </LanguageContext.Consumer>
);

export const withLocaleHoc = func => (
  <LanguageContext.Consumer>{func}</LanguageContext.Consumer>
);

import React from 'react';
import styled from 'react-emotion';

import { Col, Row, Section } from './Layout';
import { BodyText, Cite, H1, H2, Quote } from './Typography';
import { withLocale } from './LanguageProvider';

const ExperiencesMap = styled(({ className }) => (
  <iframe
    title="Experiences"
    className={className}
    height="400"
    width="100%"
    src="https://www.google.com/maps/d/embed?mid=zb18q_fdOh9o.k1q_eAl81Jrw"
  />
))`
  border: 0;
  background: rgb(160, 217, 255);
  margin-bottom: 20px;
`;

const BioSection = withLocale(({ strings }) => (
  <Section wrapped id="bio" center>
    <H1>{strings.BIO.TITLE}</H1>
    <Quote>
      <span>{strings.BIO.QUOTE_DREAMS}</span>
      <Cite>{strings.BIO.QUOTE_DREAMS_CITE}</Cite>
    </Quote>
    <Row>
      <Col flex={3}>
        <H2>{strings.BIO.BIO_TITLE}</H2>
        {strings.BIO.BIO_TEXT.map(text => (
          <BodyText dangerouslySetInnerHTML={{ __html: text }} />
        ))}
        <Quote>
          <span>{strings.BIO.QUOTE_LEARN}</span>
          <Cite>{strings.BIO.QUOTE_LEARN_CITE}</Cite>
        </Quote>
      </Col>
      <Col flex={2}>
        <H2>{strings.BIO.EXPERIENCES_TITLE}</H2>
        <ExperiencesMap />
        <Quote>{strings.BIO.EXPERIENCES_QUOTE}</Quote>
      </Col>
    </Row>
  </Section>
));

export default BioSection;

import React, { Component } from 'react';
import BioSection from './BioSection';
import { ImageSection } from './Layout';
import HomeSection from './HomeSection';
import ContactSection from './ContactSection';
import backgroundMoon from '../assets/back-moon.jpg';
import Nav from './Nav';
import { HashRouter, Route } from 'react-router-dom';
import { LanguageProvider, withLocaleHoc } from './LanguageProvider';

class App extends Component {
  homeRef = React.createRef();
  contactRef = React.createRef();
  bioRef = React.createRef();

  scrollTo = refName => {
    window.scrollTo({
      top: this[refName].current.offsetTop,
      behavior: 'smooth'
    });
  };

  render() {
    return (
      <HashRouter>
        <Route
          path="/"
          render={({ location, history }) => (
            <LanguageProvider location={location} history={history}>
              {withLocaleHoc(({ strings }) => (
                <Nav
                  links={[
                    {
                      label: strings.NAV.HOME,
                      onClick: () => this.scrollTo('homeRef')
                    },
                    {
                      label: strings.NAV.BIO,
                      onClick: () => this.scrollTo('bioRef')
                    },
                    {
                      label: strings.NAV.CONTACT,
                      onClick: () => this.scrollTo('contactRef')
                    }
                  ]}
                />
              ))}
              <div ref={this.homeRef} />
              <HomeSection />
              <div ref={this.bioRef} />
              <BioSection />
              <ImageSection src={backgroundMoon} />
              <div ref={this.contactRef} />
              <ContactSection />
            </LanguageProvider>
          )}
        />
      </HashRouter>
    );
  }
}

export default App;

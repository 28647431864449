import styled from 'react-emotion';

export const Quote = styled('blockquote')`
  background: rgba(85, 203, 255, 0.1);
  border-left: 10px solid rgb(85, 203, 255);
  // margin: 0 10px 1.5em 10px;
  margin: 0;
  font-size: 1.05em;
  padding: 0.5em 10px;
  text-align: center;
  quotes: '\\201C' '\\201D' '\\2018' '\\2019';

  &:before {
    color: rgb(85, 203, 255);
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
`;

export const Cite = styled('cite')`
  font-weight: 400;
  margin-left: 10px;
  white-space: nowrap;

  &:before {
    content: '— ';
  }
`;

export const H1 = styled('h1')`
  font-weight: 300;
  text-align: center;
  font-size: 3em;
  font-family: 'Damion', cursive;
  margin: 0;
`;

export const H2 = styled('h2')`
  font-weight: 300;
  color: rgb(85, 203, 255);
  text-transform: uppercase;
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-size: 1.3em;
`;

export const BodyText = styled('p')`
  text-indent: 0.5em;
  color: rgba(0, 0, 0, 0.9);
  font-size: 1.1em;
  margin: 0 0 1em;

  a {
    color: black;
    text-decoration: none;
    border-bottom: 2px solid rgb(85, 203, 255);
    font-weight: 400;
  }
`;

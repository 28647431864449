import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';
import Toggle from './Toggle';

const MenuIcon = styled(({ className, isActive }) => (
  <div className={`${className} ${isActive ? 'active' : ''}`}>
    <span />
  </div>
))`
  padding-top: 9px;
  cursor: pointer;
  display: block;
  width: 30px;
  height: 22px;

  span,
  span:before,
  span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 4px;
    width: 30px;
    background: white;
    position: absolute;
    display: block;
    content: '';
  }

  span:before {
    top: -9px;
  }

  span:after {
    bottom: -9px;
  }

  span {
    transition: all 300ms ease-in-out;
  }

  span:before,
  span:after {
    transition: all 500ms ease-in-out;
  }

  &.active span {
    background-color: transparent;
  }
  &.active span:before,
  &.active span:after {
    // top: 0;
  }
  &.active span:before {
    margin-top: 9px;
    transform: rotate(45deg);
  }
  &.active span:after {
    margin-bottom: 9px;
    transform: rotate(-45deg);
  }
`;

const MenuButton = styled(({ className, onClick, isActive }) => (
  <a onClick={onClick} className={className}>
    <MenuIcon isActive={isActive} />
  </a>
))`
  background: black;
  padding: 7px 5px;
  border: 1px solid #383838;
  display: block;
  margin: 10px;
  color: white;
`;

const SideMenu = styled(
  ({ isActive, className, links, language, onLanguageToggle, closeMenu }) => (
    <ul className={`${className} ${isActive ? 'active' : ''}`}>
      {links.map(({ onClick, label }, index) => (
        <Fragment>
          {!!index && (
            <li>
              <hr />
            </li>
          )}
          <li key={label}>
            <a
              onClick={() => {
                closeMenu();
                onClick();
              }}
            >
              {label}
            </a>
          </li>
        </Fragment>
      ))}
      <li>
        <hr />
      </li>
      <li>
        <Toggle
          value={language}
          onToggle={onLanguageToggle}
          options={[
            {
              label: 'EN',
              value: 'en'
            },
            {
              label: 'ES',
              value: 'es'
            }
          ]}
        />
      </li>
    </ul>
  )
)`
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.4s ease-out;

  &:not(.active) {
    top: -100%;
  }

  li {
    font-size: 2em;
    display: block;
    cursor: pointer;

    a:hover {
      color: rgb(85, 203, 255);
    }

    &:last-child {
      margin-top: 10px;
    }

    hr {
      width: 100%;
      display: block;
      width: 12vw;
      background: #383838;
      border: none;
      border-bottom: 1px solid #383838;
    }
  }
`;

class MobileMenu extends Component {
  state = {
    isActive: false
  };

  toggleActive = () => {
    this.setState(prevState => ({ isActive: !prevState.isActive }));
  };

  render() {
    const { links, language, onLanguageToggle } = this.props;
    const { isActive } = this.state;
    return (
      <div>
        <SideMenu
          isActive={isActive}
          links={links}
          language={language}
          onLanguageToggle={onLanguageToggle}
          closeMenu={this.toggleActive}
        />
        <MenuButton isActive={isActive} onClick={this.toggleActive} />
      </div>
    );
  }
}
export default MobileMenu;

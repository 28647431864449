import React, { Fragment } from 'react';
import styled from 'react-emotion';

import { Section } from './Layout';

import profile from '../assets/profile.jpg';
import backgroundVideoPoster from '../assets/video/back.jpg';
import backgroundVideoMp4 from '../assets/video/back.mp4';
import backgroundVideoOgv from '../assets/video/back.ogv';
import backgroundVideoWebm from '../assets/video/back.webm';
import { withLocale } from './LanguageProvider';
import CanvasVideoPlayer from '../canvas-video-player';

const Profile = styled(
  withLocale(({ className, strings }) => (
    <div className={className}>
      <img src={profile} alt="Álvaro Reneses" />
      <h1>Álvaro Reneses</h1>
      <h2>{strings.HOME.LOCATION}</h2>
    </div>
  ))
)`
  margin-top: -20px;
  text-align: center;
  z-index: 1;
  color: white;

  img {
    width: 250px;
    height: 250px;
    border-radius: 100%;
    box-shadow: 0 0 10px black;
  }

  h1,
  h2 {
    text-shadow: 0 0 10px black;
  }

  h1 {
    font-size: 2em;
    font-family: 'Damion', cursive;
    margin: 0;
  }

  h2 {
    font-size: 1em;
    line-height: 1em;
    margin: 0;
  }
`;

class BaseBackgroundVideo extends React.Component {
  componentDidMount() {
    const isIphone =
      navigator.userAgent.indexOf('iPhone') >= 0 ||
      /iPad|iPhone|iPod/.test(navigator.platform);

    if (isIphone) {
      new CanvasVideoPlayer({
        videoSelector: '#background-video',
        canvasSelector: '#background-canvas',
        framesPerSecond: 25,
        hideVideo: true, // should script hide the video element
        autoplay: true,
        resetOnLastFrame: true,
        loop: true
      });
    }
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <video
          id="background-video"
          loop
          autoPlay
          playsinline
          muted
          poster={backgroundVideoPoster}
        >
          <source src={backgroundVideoMp4} type="video/mp4" />
          <source src={backgroundVideoWebm} type="video/webm" />
          <source src={backgroundVideoOgv} type="video/ogg" />
        </video>
        <canvas id="background-canvas" />
      </div>
    );
  }
}

const BackgroundVideo = styled(BaseBackgroundVideo)`
  video {
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
  }

  #background-canvas {
    z-index: -100;
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
  }
`;

const Links = styled(({ links, className }) => (
  <div className={className}>
    {links.map(({ href, icon, label }) => (
      <a
        key={icon}
        href={href}
        title={label}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className={`fa ${icon}`} />
      </a>
    ))}
  </div>
))`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  font-size: 2.2em;
  text-align: center;

  a {
    color: white;
    margin: 0 5px;
    text-shadow: 0 0 10px black;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: rgb(85, 203, 255);
    }
  }
`;

const HomeSection = () => (
  <Fragment>
    <BackgroundVideo />
    <Section id="home" center transparent>
      <Profile />
      <Links
        links={[
          {
            href: 'mailto:me@reneses.io',
            label: 'Email',
            icon: 'fa-envelope-square'
          },
          {
            href: 'https://www.linkedin.com/in/alvaroreneses',
            label: 'LinkedIn',
            icon: 'fa-linkedin-square'
          },
          {
            href: 'https://github.com/reneses',
            label: 'Github',
            icon: 'fa-github'
          }
        ]}
      />
    </Section>
  </Fragment>
);

export default HomeSection;

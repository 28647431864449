import React, { Component } from 'react';
import styled from 'react-emotion';

const ToggleContainer = styled('div')`
  padding: 0;
  background: black;
  position: relative;
  z-index: 1;
  display: flex;
  border: 1px solid #383838;
  color: white;
`;

const ToggleOption = styled('div')`
  flex: 1;
  padding: 5px 10px;
  cursor: pointer;
  background: none;
  color: white;
`;
const ToggleSelector = styled('div')`
  background: #383838;
  position: absolute;
  // width: calc(50% + 2px);
  width: 50%;
  top: 0;
  bottom: 0;
  z-index: -1;
  transition: all 0.2s ease-in;
  left: ${({ left }) => (left ? '0' : '50%')};
`;

export default class Toggle extends Component {
  constructor(props) {
    super(props);

    const { options } = this.props;
    this.state = {
      left: true,
      value: options[0].value
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        left: props.value === props.options[0].value,
        value: props.value
      };
    }

    return null;
  }

  render() {
    const { options, onToggle } = this.props;

    return (
      <ToggleContainer>
        <ToggleSelector left={this.state.left} />
        {options.map(({ label, value }) => (
          <ToggleOption
            key={value}
            onClick={() =>
              this.setState({ left: value === options[0].value }, () =>
                onToggle(value)
              )
            }
          >
            {label}
          </ToggleOption>
        ))}
      </ToggleContainer>
    );
  }
}

import React, { Fragment } from 'react';
import styled from 'react-emotion';

const Marker = ({ id }) => <div id={id} />;

const SectionWrapper = styled('div')`
  max-width: 960px;
  margin: 0 auto;
`;

export const Section = styled(
  ({ className, children, id, wrapped = false }) => (
    <Fragment>
      <Marker id={id} />
      <section className={className} id={id}>
        {wrapped ? <SectionWrapper>{children}</SectionWrapper> : children}
      </section>
    </Fragment>
  )
)`
  position: relative;
  min-height: 100vh;
  padding: 60px 20px;
  z-index: 2;
  ${({ transparent }) => !transparent && 'background-color: white;'} ${({
    center
  }) =>
    center &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const Row = styled('div')`
  display: flex;
  margin: 0 -20px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Col = styled('div')`
  padding: 0 20px;
  flex: ${({ flex = '1' }) => flex};
`;

export const ImageSection = styled('div')`
  min-width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.src});

  @media screen and (min-width: 650px) {
    background-attachment: fixed;
  }
`;

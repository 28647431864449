import React from 'react';
import styled from 'react-emotion';

import { Col, Row, Section } from './Layout';
import { BodyText, Cite, H1, H2, Quote } from './Typography';
import { withLocale } from './LanguageProvider';

const ContactIcon = styled('div')`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
  color: white;
  background-color: rgb(85, 203, 255);
  margin-bottom: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 4em;
    color: white;
  }

  // @media screen and (max-width: 640px) {
  //   height: 100px;
  //   width: 100px;
  //
  //   i {
  //     font-size: 4em;
  //   }
  // }
`;

const ContactCard = styled(({ className, icon, label, href }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    <ContactIcon>
      <i className={`fa ${icon}`} />
    </ContactIcon>
    <div>{label}</div>
  </a>
))`
  text-align: center;
  color: black;
  display: inline-block;
  text-decoration: none;
  margin: 0 20px 20px;
`;

const ContactIcons = styled(({ className }) => (
  <div className={className}>
    <ContactCard
      label="me@reneses.io"
      icon="fa-envelope-o"
      href="mailto:me@reneses.io"
    />
    <ContactCard
      label="alvaroreneses"
      icon="fa-linkedin-square"
      href="https://www.linkedin.com/in/alvaroreneses"
    />
    <ContactCard
      label="@reneses"
      icon="fa-github"
      href="https://github.com/reneses"
    />
  </div>
))`
  text-align: center;
`;

const ContactSection = withLocale(({ strings }) => (
  <Section wrapped id="contact" center>
    <H1>{strings.CONTACT.TITLE}</H1>
    <Quote>
      <span>{strings.CONTACT.QUOTE_COMMUNICATION}</span>
      <Cite>{strings.CONTACT.QUOTE_COMMUNICATION_CITE}</Cite>
    </Quote>
    <H2>{strings.CONTACT.SUBTITLE}</H2>
    <BodyText>{strings.CONTACT.TEXT}</BodyText>
    <ContactIcons />
  </Section>
));

export default ContactSection;

import React from 'react';
import { render } from 'react-snapshot';

import 'font-awesome/css/font-awesome.css';
import 'normalize.css';
import './index.css';

import WebFont from 'webfontloader';
import App from './components/App';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

WebFont.load({
  google: {
    families: ['Lato:300,400,300italic', 'Damion', 'sans-serif']
  }
});

render(<App />, document.getElementById('root'));
